/** @format */

import { bindable } from 'aurelia-framework';
import provisionService from 'services/api/provisionService';

export class ResellersSearchList {
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;

  constructor() {
    this.provisionService = provisionService;

    this.resellers = [];
    this.qLoad = null;
    this.loading = false;
  }

  bind() {
    this.qLoad = this.loadingResellers();
  }

  loadingResellers() {
    this.loading = true;
    return this.provisionService.getResellers().then(resellers => {
      this.resellers = resellers.filter(f => f.id != 1);
      this.loading = false;
    });
  }

  selectedIdsChanged(ids) {
    if (!this.qLoad) return;

    this.qLoad.then(_ => {
      this.selectedIds = ids;
    });
  }
}
