import {
  bindable,
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  TranslationService
} from 'services/translationService';
import './simple-search-list.scss';

const ALL_ITEMS = {
  id: -1,
  selected: true
};

/**
 * This list "returns" [-1] when selecting all items, to automatically select all even when new items are added afterwards
 * 
 * this could be optional in case we need a list where 'all' only means the current items on the list and not any added after.
 */
@inject(TranslationService)
export class SimpleSearchList {

  //
  @bindable label;
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;
  @bindable returnAll;

  //relay props to <simpe-list>
  @bindable items;
  @bindable loading;
  @bindable displayField;
  @bindable grouped;
  @bindable maxHeight;
  @bindable minHeight;
  @bindable itemFilter;

  constructor(_TranslationService) {
    this.items = [];
    this.selectedItems = [];
    this.displayField = null;
    this.label = null;
    this.activeInput = false;

    this.loading = false;
    this.itemFilter = '';
    this.multiSelect = true;
    this.returnAll = true;

    this.filterFilters = 'name';

    this.onItemSelect = this.onItemSelect.bind(this);
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.ts = _TranslationService;
  }

  attached() {
    document.addEventListener('click', this.onDocumentClick);
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.activeInput) this.mouseEventClick(e, false);
  }

  mouseEventClick(e, status) {
    this.activeInput = status;

    e.stopImmediatePropagation();
  }

  @computedFrom('activeInput', 'selectedItems', 'loading', 'selectedIds', 'items')
  get selectedName() {
    if (this.selectedItems && this.selectedItems.length) {
      if (this.selectedItems.length == 1) {
        return this.selectedItems[0].name;
      } else if (this.selectedItems.length > 1) {
        return this.ts.getCap('selected').replace('%d', this.selectedItems.length);
      }
    } else {
      return '';
    }
  }

  //find a better way to handle this.
  //passing it down to simpe-list does not make much sense and involved filtering by js on the groups
  //
  groupedChanged() {
    this.filterFilters = this.grouped ? 'name,groupName' : 'name';
  }

  multiSelectChanged(value) {
    this.multiSelect = value === true || value === 'true';
    this.setAll(false);
  }

  itemsChanged(value) {
    //persist selected states, if selected-ids used
    if (this.selectedIds) {
      this.selectedIdsChanged(this.selectedIds);
    } else {
      this.selectedItems = [];
      this.selectedItems = this.items.filter(function (item) {
        return item.selected;
      });
    }
  }

  selectedIdsChanged(ids = []) {
    if (this.items && this.items.length) {
      let _ids = ids instanceof Array ? ids : [ids];

      //if select all mode
      this.allItems = ((_ids.length === 1 && _ids[0] == ALL_ITEMS.id) || _ids.length === this.items.length);
      if (this.allItems) {
        this.setAll(true);
      } else {
        this.items.forEach(x => x.selected = (_ids.indexOf(x.id) > -1));
      }

      this.selectedItems = [];
      this.selectedItems = this.items.filter(function (item) {
        return item.selected;
      });
    }
  }

  onItemSelect(item, all) {
    if (!this.multiSelect) {
      this.setAll(false);
    }

    if (item && !item.disabled) {
      item.selected = !(item.selected || false);
    }

    if (all === true) {
      this.allItems = !this.allItems;
      this.setAll(this.allItems);
    } else {
      this.allItems = false;
    }

    if (this.onListChanged) {
      let selected = this.allItems && this.returnAll ? [ALL_ITEMS] : (this.items || []).filter(x => x.selected)
      this.onListChanged(selected);
    }

    this.selectedItems = [];
    this.selectedItems = this.items.filter(function (item) {
      return item.selected;
    });

    if (this.selectedIds) {
      this.selectedIds = this.items.filter(function (item) {
        return item.selected;
      }).map(function (item) {
        return item.id;
      });
    }
  }

  onGroupSelect(group, all) {
    if (!this.multiSelect || !this.grouped) {
      return;
    }

    if (group.items) {
      let selectedItems = group.items.filter((item) => item.selected);
      var disableAll = (selectedItems.length === group.items.length) ? true : false;
      group.items.forEach(function (item) {
        if (!item.disabled) {
          item.selected = disableAll ? false : true;
        }
      })
    }

    this.selectedItems = [];
    this.selectedItems = this.items.filter(function (item) {
      return item.selected;
    });

    if (this.onListChanged) {
      let selected = (this.items || []).filter(x => x.selected)
      this.onListChanged(selected);
    }
  }

  setAll(selected) {
    this.items.forEach(a => {
      if (!a.disabled) a.selected = selected
    });
    this.selectedItems = [];
    this.selectedItems = this.items.filter(function (item) {
      return item.selected;
    });
  }
}
