import { bindable } from 'aurelia-framework';
import Utils from 'utils/utils';
import './slim-list.scss';

export class SlimList {

  @bindable items;
  @bindable cctv;
  @bindable grouped;
  @bindable groupField;
  // @bindable groupFieldId;
  @bindable displayField;
  @bindable onItemClick;
  @bindable isScrolling;

  constructor() {
    this._items = [];
    this.isScrolling = false

    this.displayField = 'name';
    this.groupField = 'groupName';
  }

  itemsChanged(value, oldvalue) {
    this.buildList();
  }

  buildList() {
    if (this.grouped) {
      //if we ever need the group field to different than the group name field
      // const gFindName = (g) => g && g.length && g[0][this.groupField] || null;

      let groups = Utils.groupArrayObj(this.items, this.groupFieldId || this.groupField, this.cctv);
      let lgroups = Object.keys(groups).map(g => { return { name: g || g, items: groups[g] } });
      this._items = lgroups;

    }
  }

  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
  }
}
