/** @format */

import {
  bindable,
  inject,
  computedFrom
} from 'aurelia-framework';
import {
  TranslationService
} from 'services/translationService';

import './simple-list.scss';

@inject(TranslationService)
export class SimpleList {
  @bindable items;
  @bindable sortProperties;
  @bindable displayField;
  @bindable noItemsLabel;

  @bindable cctv;
  @bindable grouped;
  @bindable multiSelect;
  @bindable loading;
  @bindable groupField;
  @bindable groupFieldId;

  @bindable onItemSelect;
  @bindable onGroupSelect;

  @bindable fullWidth;
  @bindable maxHeight;
  @bindable minHeight;
  @bindable simpleListScroll;
  @bindable preserveScroll;
  @bindable scrollEffect;

  constructor(_TranslationService) {
    this.ts = _TranslationService;

    this._items = [];
    this.loading = false;
    this.isGrouped = false;
    this.multiSelect = false;
    this.displayField = 'name';
    this.sortProperties = {
      field: 'name',
      reverse: false
    };
    this.noItemsLabel = 'no_items';

    this.groupFieldId = 'groupId';
    this.groupField = 'groupName';

    this.maxHeight = 'initial';
    this.minHeight = 0;

    this.preserveScroll = false;
    this.simpleListScroll = false;
    this.scrollEffect = true;
    this.scrollPosition = {
      top: 0,
      left: 0
    }

    this.noGroup = {
      id: -1,
      name: this.ts.getCap('nogroup'),
      weight: Array(15).join('0')
    };

    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.onGroupClick = this.onGroupClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  @computedFrom('maxHeight', 'minHeight')
  get cssStyle() {
    this.cctv ? this.maxHeight = '20rem' : 'initial';
    return {
      maxHeight: this.maxHeight,
      minHeight: this.minHeight,
      'overflow-x': 'hidden'
    };
  }

  groupFieldChanged(groupField) {
    if (groupField) {
      this.initList();
    }
  }

  loadingChanged(value) {
    this.loading = value === true || value === 'true';
  }

  groupedChanged() {
    this.initList();
  }

  itemsChanged() {
    this.initList();
  }

  initList() {
    this.simpleListScroll = false;
    if (this.items) {
      if (this.grouped && this.groupField && this.items.length) {
        let groups = {};
        this.items.forEach(item => {
          let grp = !item[this.groupFieldId] ?
            this.noGroup : {
              id: item[this.groupFieldId],
              name: item[this.groupField]
            };

          groups[grp.id] = groups[grp.id] || {
            ...grp,
            items: [],
            weight: grp.weight || grp.name,
            expanded: true
          };
          groups[grp.id].items.push(item);
        });
        this._items = Object.keys(groups).map(g => groups[g]);
      } else {
        this._items = this.items;
      }

      this.checkPreserveScroll(true);
    }
  }

  onItemClick(item, e) {
    if (this.onItemSelect) {
      this.checkPreserveScroll();
      this.onItemSelect(item, e);
    }
  }

  onGroupClick(group, e) {
    if (this.onGroupSelect) {
      this.checkPreserveScroll();
      this.onGroupSelect(group, e);
    }
  }

  onHeaderClick(group) {
    if (this.onGroupSelect) {
      let {
        id,
        name
      } = group;
      if (id != this.noGroup.id) {
        let items = group.items.map(i => {
          return {
            ...i
          };
        });

        this.checkPreserveScroll();
        this.onGroupSelect({
          id,
          name,
          items
        });
      }
    }
  }

  checkPreserveScroll(restore = false) {
    if (this.preserveScroll && this.scrollContainerRef) {
      if (restore) {
        setTimeout(() => {
          if (this.scrollContainerRef && this.scrollContainerRef.scrollTo) {
            this.scrollContainerRef.scrollTo(this.scrollPosition.left, this.scrollPosition.top)
          }
        }, 0);
      } else {
        this.scrollPosition = {
          top: this.scrollContainerRef.scrollTop,
          left: this.scrollContainerRef.scrollLeft
        };
      }
    }
  }

  onScroll(e) {
    if(!this.scrollEffect) return;
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.simpleListScroll = true;
    } else {
      this.simpleListScroll = false;
    }
  }
}
