/** @format */

import {
  bindable
} from 'aurelia-framework';
import userService from 'services/api/userService';

export class UsersSearchList {
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;
  @bindable includeSelf;

  constructor() {
    this.userService = userService;

    this.users = [];
    this.selfId;
    this.accountId;
    this.qLoad = null;
    this.loading = false;
    this.includeSelf = true;
  }

  bind() {
    this.loading = true;
    this.userService.getSelf().then(self => {
      this.accountId = self.accountId;
      this.selfId = self.id;
      this.qLoad = this.loadUsers();
    }).catch(e => {
      this.loading = false;
    })
  }

  loadUsers() {
    return this.userService.getUsers(null, this.accountId).then(users => {
      if(!this.includeSelf) {
        this.users = users.filter(u => u.id != this.selfId);
      } else {
        this.users = users;
      }
      
      this.loading = false;
    });
  }

  selectedIdsChanged(ids) {
    if (!this.qLoad) return;

    this.qLoad.then(() => {
      this.selectedIds = ids;
    });
  }
}
